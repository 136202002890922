import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Plank 3×1:00`}</p>
    <p>{`Reverse Hyperextension 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`Snatch Grip Deadlift + Snatch Pull 5×2\\@55% Conventional Deadlift 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`10-Deadlifts (225/155)(RX+ 275/185)`}</p>
    <p>{`20-Calorie Row`}</p>
    <p>{`30-Situps`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Saturday, July 18th, is our Ville in-house competition!  Sign up with
the link provided in our latest email or just email us and we’ll sign
you up.  RX and Scaled divisions so this competition is for all of our
members.  Arrive by 8:30am, first wod to start at 9:00.  `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      